import React from 'react';
import FAQ from "./faq"
import { Typography } from '@mui/material';
import './Home.css'
import Clone from "../assets/images/clone-clones.gif"


const HomeBar = () => {
  const question_answers = [
    {
      "question": "Who even are you?",
      "answer": `I am Zain, software engineer and a newly self acclaimed writer. :) 
      My specialization is backend development, as you can see with the design of the blogpost, 
      i am not the best at UI. So apologies for that.`,
      "gif_link": "https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExZTcyZndhdnloMzU1bGNreTdxN3N4dHFyd296YWQ0b3FtYmM2YjB2eSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/fnuSiwXMTV3zmYDf6k/giphy.gif"
    },
    {
      "question": "Why should i even read your blogs?",
      "answer": `Philosopy of my blog is to dive deeper within the concept until i peel all the abstraction
      layer behind it.
      I try to distill hard concepts into more managebale and disgestable information.
      My approach is akin to wikipedia`,
      "gif_link": "https://media.giphy.com/media/0Vv0Ne2CnOClIExIuL/giphy.gif?cid=790b76111q3kiozopo2yc6mrczjzlf99f6hr294y3x51cyj8&ep=v1_gifs_search&rid=giphy.gif&ct=g"
    },
    {
      "question": "Is this yet another AI genereated blog?",
      "answer": `I feel like, AI generated blogs are intended as an insult to the user who is reading it,
        They tend to waste the time of the user just to present them with the rubbish content
        in the most boring monotonous tone, which will have anyone yawning, I respect the readers
        of my blog, this will always be organic and you would feel the tone as if another human 
        is talking to you, I intend to research and then only present the info you see 
        on this blog, behind each blog you won't find gpt but hard work only.(touche)`,
      "gif_link": `${Clone}`
      },
    {
      "question": "Why should not i solely rely on abstraction, why do i even need to know underneath stuff?",
      "answer": `I believe in the belief if you don't dig deep, you will be unable to see the beauty that lies beneath,
                  and Anyway, after you remove all the abstraction layers`,
      "gif_link": "https://tenor.com/view/why-whatever-gif-2313962705315841819"
    },
    {
      "question": "But there are many blogs written by experts in the field, why should not i read those?",
      "answer": `Correctly pointed, I am not an expert in my field, and there are many bloggers out there
      that are much more experienced and expert in doing this, but as they say we stand on the 
      shoulder of the giants that came before us.
      My blogs will be inspired by these experts blogs, resources, research papers, 
      The topics i write on, you can be assured have been written after combining and correlating
      all the best resources on that topic.`
    },
    {
      "question": "I still feel gaps in my knowledge regarding the topic you covered, What should i do?",
      "answer": `I am happy to receive feedback from the readers, i will try my best to answer all 
        your queries, and will be happy to pick up any new topic you want me to cover, 
        This also means i might have some topic to read on`
    },
    {
      "question": "I like your style i want you to cover some other topic i have in mind?",
      "answer": "Sure, I love taking queries and suggestions from users,"
    },
    {
      "question": "Ok enough talk take me to the blogs",
      "answer": "Here you go"
    }
  ]
  return (
    <div className='centered-container'>
      <Typography variant="h3">Hey There Friend,</Typography>
      <Typography variant="p">(Chill, not gonna make overused Hello World joke)</Typography>
      <Typography variant="h5">Since you landed on this page you might have some questions</Typography>
      <Typography variant="h5"></Typography>
      <div>
          {question_answers.map((pair,index) => {
              return <FAQ 
              question={pair["question"]} answer={pair["answer"]} 
              gif_link={pair["gif_link"]}
              key={index} />
            })}
      </div>
    </div>
  )
};

export default HomeBar;