import React from 'react';
import { Link } from 'react-router-dom';
import './Posts.css';

const Posts = () => {
  const blogPosts = [
    {
      id: 'historicalinternet',
      title: 'The Historical Evolution of the Internet',
      description: 'A deep dive into how the Internet has evolved over time and its impact on society.',
      date: 'February 27, 2024',
      readTime: '10 min read'
    },
    {
      id: 'reverseproxy',
      title: 'Understanding Reverse Proxies',
      description: 'An exploration of reverse proxies and their role in modern web architecture.',
      date: 'February 25, 2024',
      readTime: '8 min read'
    }
  ];

  return (
    <div className="posts-container">
      <h1>Blog Posts</h1>
      <div className="posts-grid">
        {blogPosts.map(post => (
          <Link to={`/${post.id}`} key={post.id} className="post-card">
            <article>
              <h2>{post.title}</h2>
              <p className="post-description">{post.description}</p>
              <div className="post-meta">
                <span className="post-date">{post.date}</span>
                <span className="post-read-time">{post.readTime}</span>
              </div>
            </article>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Posts;