import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Me</h1>
      <div className="about-content">
        <section className="bio-section">
          <h2>Biography</h2>
          <p>I am a software engineer with a passion for building scalable and efficient systems. 
            I am currently working as a software engineer at Moengage.
            I like to talk all things software.</p>
        </section>

        <section className="expertise-section">
          <h2>Areas of Interests</h2>
          <ul>
            <li>Backend Development</li>
            <li>Scalable Systems</li>
            <li>System Optimization</li>
          </ul>
        </section>

        <section className="experience-section">
          <h2>Experience</h2>
          <div> Currently i am working as a senior software engineer at Moengage.
            When not working you will find me diving deeper into the world of software.
          </div>
        </section>

        <section className="education-section">
          <h2>Education</h2>
          <div className="education-item">
            <h3>Masters in Computer Science and Engineering</h3>
          </div>
        </section>

        <section className="contact-section">
          <h2>Get in Touch</h2>
          <p>Feel free to reach out to me through any of these platforms:</p>
          <ul className="contact-links">
            <li>zain.khan@moengage.com</li>
            <li>linkedin.com/in/zain-khan-dev</li>
            <li>https://github.com/zain-khan-dev</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default About;