import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  Divider,
  IconButton,
  InputAdornment,
  ThemeProvider,
  CssBaseline,
  createTheme
} from '@mui/material';
import { Eye, EyeOff, Mail, Lock, UserPlus, LogIn } from 'lucide-react';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
          margin: 0,
          padding: 0,
        }
      }
    }
  }
});

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Grid item xs={12} md={4}>
    <Paper
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        background: 'rgba(255, 255, 255, 0.05)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Icon size={24} style={{ color: '#90caf9', marginRight: '8px' }} />
        <Typography variant="h6" sx={{ color: '#90caf9' }}>
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Paper>
  </Grid>
);

const AuthPage = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement authentication
    navigate('/urlshortener/dashboard');
  };

  const handleGuestAccess = () => {
    navigate('/urlshortener/dashboard');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
          display: 'flex',
          flexDirection: 'column',
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography variant="h2" component="h1" 
              sx={{ 
                fontWeight: 'bold',
                background: 'linear-gradient(45deg, #90caf9 30%, #f48fb1 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 2
              }}
            >
              URL Shortener
            </Typography>
            <Typography variant="h5" sx={{ color: 'text.secondary', mb: 4 }}>
              Transform your long URLs into short, trackable links
            </Typography>
          </Box>

          {/* Features Grid */}
          <Grid container spacing={4} sx={{ mb: 6 }}>
            <FeatureCard
              icon={props => <Box component="span" sx={{ display: 'flex' }}>⚡</Box>}
              title="Fast & Reliable"
              description="Generate short URLs instantly with our optimized service. Built for speed and reliability."
            />
            <FeatureCard
              icon={props => <Box component="span" sx={{ display: 'flex' }}>🔒</Box>}
              title="Secure"
              description="Enterprise-grade security with SSL encryption and secure data storage."
            />
            <FeatureCard
              icon={props => <Box component="span" sx={{ display: 'flex' }}>📊</Box>}
              title="Analytics"
              description="Track your link performance with detailed click analytics and insights."
            />
          </Grid>

          {/* Auth Form */}
          <Container maxWidth="sm">
            <Paper
              elevation={24}
              sx={{
                p: 4,
                background: 'rgba(30, 30, 30, 0.8)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
              }}
            >
              <Box component="form" onSubmit={handleSubmit}>
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 3 }}>
                  {isLogin ? 'Welcome Back' : 'Create Account'}
                </Typography>

                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail size={20} />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleChange}
                  margin="normal"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock size={20} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {!isLogin && (
                  <TextField
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    margin="normal"
                    required
                  />
                )}

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  startIcon={isLogin ? <LogIn size={20} /> : <UserPlus size={20} />}
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1.5,
                    background: 'linear-gradient(45deg, #90caf9 30%, #f48fb1 90%)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #64b5f6 30%, #f06292 90%)',
                    },
                  }}
                >
                  {isLogin ? 'Sign In' : 'Sign Up'}
                </Button>

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Button
                    onClick={() => setIsLogin(!isLogin)}
                    sx={{ color: 'text.secondary' }}
                  >
                    {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Sign In"}
                  </Button>
                </Box>

                <Divider sx={{ my: 2 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    OR
                  </Typography>
                </Divider>

                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleGuestAccess}
                  sx={{
                    mt: 1,
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                    color: 'text.secondary',
                    '&:hover': {
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      background: 'rgba(255, 255, 255, 0.05)',
                    },
                  }}
                >
                  Continue as Guest
                </Button>
              </Box>
            </Paper>
          </Container>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AuthPage;
