// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: `"Palatino Linotype", "Book Antiqua", Palatino, serif`,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `"Palatino Linotype", "Book Antiqua", Palatino, serif !important`,
        },
      },
    },
  },
});

export default theme;
