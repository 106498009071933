import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Button, Grid } from '@mui/material';
import { Link as LinkIcon } from 'lucide-react';

function Projects() {
    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                My Projects
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                URL Shortener
                            </Typography>
                            <Typography variant="body2" color="text.secondary" paragraph>
                                A modern URL shortening service with a clean, dark-themed interface.
                            </Typography>
                            <Button
                                component={Link}
                                to="/urlshortener"
                                variant="contained"
                                startIcon={<LinkIcon size={16} />}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Open URL Shortener
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Add more project cards here */}
            </Grid>
        </Box>
    );
}

export default Projects;