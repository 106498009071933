import { Typography } from "@mui/material";
import circuitswitching from "../../assets/manimanimations/CircuitSwitching.mp4"
import CardMedia from '@mui/material/CardMedia';
import FirstMessage from "../../assets/manimanimations/FirstMessage.mp4"

// font used-  Palatino Linotype
const HistoricalEvolutionInternet = () => {
    return (
        <div>
            <Typography variant="h4" sx={{fontWeight: "bold", textAlign: "center"}}>Evolution of Communication on the Internet</Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Before the Internet</Typography>
            <Typography variant="p">We're talking about a time before the 1960s when communication was done through telephone and radiotelegraphy. 
                But these mediums were limited to point-to-point channels. 
                At that time, computers were not only expensive but also massive in size. The primary users of computers were 
                government institutions, research labs, and large corporations. Communication between these computers was almost inconceivable.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Here Comes the First Hero in Our Story - J.C.R. Licklider</Typography>
            <Typography variant="p">
                A visionary of his time, J.C.R. Licklider proposed the concept of an Intergalactic Network, envisioning a global 
                computer network where users could access programs and data from anywhere. His ideas laid the foundation for modern networking, 
                emphasizing an open electronic commons, universal data accessibility, and a system that would facilitate interaction 
                between governments, corporations, institutions, and individuals.
            </Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Why ARPANET Was Needed</Typography>
            <Typography variant="p">
                ARPANET came out of frustration that there were only a limited number of large, powerful research computers in the country, and many researchers 
                who needed access to them were geographically separated from these machines.
            </Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Initiative Towards ARPANET</Typography>
            <Typography>
                Larry Roberts was hired as the project manager in ARPA IPTO.
                He was tasked with exploring the technical questions around message size, content, and network protocols. 
                His initial proposal suggested connecting all mainframe computers directly in a mesh topology. However, 
                this idea was dropped due to the high cost of computing and the impracticality of dedicating so many resources to network administration.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Circuit Switching and Why We Moved On</Typography>
            <Typography variant="p">
                Before the internet, most communication networks were based on circuit switching. 
                In circuit switching, a dedicated communication channel is established, and physical lines or bandwidth are reserved 
                exclusively for that connection. 
                This means no other device can use those resources at that time. As you might have guessed, 
                this led to significant resource wastage because the system often sat idle.
                Hence, the need arose to build an infrastructure that could optimize resource utilization.
            </Typography>
            <CardMedia
                component="video"
                src={circuitswitching}
                autoPlay
                controls
                loop
                muted
                sx={{
                    width: "50%",
                    height: "50%",
                    display: "block", // Ensures margin auto works
                    margin: "0 auto", // Centers horizontally
                    textAlign: "center", // Has no effect on block elements but keeps it consistent
                  }}
                // 50% of parent size
            />
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Enter Packet Switching</Typography>
            <Typography variant="p">
                The idea of packet switching is attributed to two people:
                - Paul Baran, who first developed the concept at RAND.
                - Donald Davies, who independently came up with a similar concept at the National Physical Laboratory (NPL).
            </Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Packet Switching - The Proposal</Typography>
            <Typography>
                It was based on three key principles:
                - A decentralized system with multiple paths between nodes.
                - Messages divided into smaller units called packets.
                - Messages delivered through indirect channels instead of a dedicated line.
            </Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>How Packet Switching Became Part of ARPANET</Typography>
            <Typography variant="p">
                Donald Davies and his team, who were independently working on packet switching, 
                proposed its adoption in ARPANET, which proved to be far more efficient than circuit switching.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>IMP - Interface Message Processor</Typography>
            <Typography>
            was a specialized packet-switching device, designed as an early form of what we now call a router. 
            Wesley Clark proposed using these instead of mainframe computers for networking."
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>How klienrock came into picture</Typography>
            <Typography></Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Bringing It All Together</Typography>
            <Typography>
                The ideas of Baran, Davies, and Clark were eventually combined to form the first version of ARPANET. 
                By implementing the ideas of packet switching, 
                ARPANET’s speed proposed speed were to be 50 kbits
            </Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Let the Bidding Wars Begin</Typography>
            <Typography>
                Meanwhile, work on the Interface Message Processor (IMP) continued. Larry Roberts and Barry Wessler 
                finalized the specifications based on a Stanford Research Institute report. A Request for Quotation was issued to 140 bidders, 
                but only 12 companies submitted proposals. By the end of the year, the contract was awarded to Bolt, Beranek, and Newman (BBN).
            </Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Final Specification of IMP</Typography>
            <Typography>
                Each IMP could support up to four local hosts and communicate with up to six remote IMPs.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Setting the Stage for Communication</Typography>
            <Typography>
                The initial ARPANET setup included four sites, strategically chosen for their ability to provide specialized network services:
                1. University of California, Los Angeles (UCLA)
                2. Stanford Research Institute (SRI) - Focused on Human Intellect Augmentation Systems
                3. University of California, Santa Barbara - Specialized in interactive graphics
                4. University of Utah - Focused on advanced 3D graphics
            </Typography>
            
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Let's Send the First Message</Typography>
            <Typography>
                The first message sent over ARPANET was a LOGIN attempt from UCLA to SRI. 
                The intended message was “LOGIN,” but due to a system crash, only the first two letters—“LO”—were transmitted. 
                This is often nostalgically referenced as “Lo and Behold.”
            </Typography>
            <CardMedia
                component="video"
                src={FirstMessage}
                autoPlay
                controls
                loop
                muted
                sx={{
                    width: "50%",
                    height: "50%",
                    display: "block", // Ensures margin auto works
                    margin: "0 auto", // Centers horizontally
                    textAlign: "center", // Has no effect on block elements but keeps it consistent
                  }}
                // 50% of parent size
            />
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Improving the error control protocol</Typography>
            <Typography>
                Initially NCP was used to communicate between hosts, but lacked error control, retransmission 
                or congestion handling, It relied on ARPANET to provide the reliability, if any packet was lost in transmission 
                the whole communication would come to halt.
                We needed better error control and congestion handling without changing the internal structure of ARPANET.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Birth of TCP/IP</Typography>
            <Typography>
            TCP was a protcol for how computers should talk to each other in a reliable way over a network.
            Vint Cerf and Bob Kahn were the first to come up with this idea.
            The goal was to create a set of rules that different operating systems could follow, 
            making sure they could all work together. This open design made it easy for new networks and computers to connect 
            by simply following the same rules.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}> Functions it provided</Typography>
            <Typography>
                Since data was broken into smaller packets and sent across different paths in a network,  
                sometimes packets could get lost, arrive out of order, or run into issues if a network node (router) had problems.  
                TCP made sure these issues were handled.  
                Each packet was given a sequence number so that, even if they arrived in the wrong order,  
                they could be rearranged correctly at the destination.  
                If any packets were lost, TCP could detect it and request them to be sent again.  
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Separating TCP - TCP and UDP</Typography>
            <Typography>
                Initially TCP was designed to handle both reliable and unreliable communication multiple.
                Later on TCP was separated into just being connection oriented providing reliable communication through 
                error checking, retransmission of lost packets, and ordered delivery..
                but incorporating these cool functionalities came at a cost. there was additional latency due to this, 
                There were emerging applications (Video calls, live streaming, gaming) that could tolerate packet loss 
                but could not compromise over the latency. Hence another protocol UDP was developed for application 
                that didnt require those functionalities.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Protocol War</Typography>
            <Typography>
            Another protocol backed by the European Union was proposed called the OSI Model. It introduced a layered approach to network 
            communication, but it was too complex and slow to adopt, leading to its eventual decline. 
            However, it remains widely used in academics for teaching networking concepts.
            Other companies like IBM, Novell, and DEC also developed their own networking protocols, 
            but all were eventually superseded by TCP/IP.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Standardising TCP/IP</Typography>
            <Typography>
                In order to promote the use of TCP/IP across the internet multiple measures were taken.
                Berkeley Unix played a crucial role in spreading TCP/IP adoption by including it 
                as a default networking stack
                By the time competing protocols were being developed, TCP/IP had already been widely deployed
                anuary 1, 1983, known as 'Flag Day,' marked the complete transition of ARPANET from NCP to TCP/IP, 
                requiring all connected systems to switch at the same time
                Many engineers involved in the transition later on wore T-shirts saying, 'I Survived TCP/IP'.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>Adopting Modern Internet - Beyong ARPANET</Typography>
            <Typography>
                The NSF created NSFNET to connect five supercomputer centers, helping universities and research labs share resources. 
                Over time, it expanded and replaced ARPANET as the main research network. By 1990, commercial networks began using NSFNET, 
                though initially limited to research and education. In 1995, the backbone was shut down, and private ISPs took over—leading to
                the modern internet.
            </Typography>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>References</Typography>
            <Typography>
                - https://www.icann.org/en/blogs/details/the-first-message-transmission-29-10-2019-en
            </Typography>
        </div>
    );
};

export default HistoricalEvolutionInternet;

// Parallel to this, the idea of time-sharing computers was gaining traction. Project MAC, envisioned and launched by DARPA, 
// played a crucial role in shaping early concepts of host-to-host communication, eventually influencing the development of ARPANET. 
// This was meant to provide researchers, scientists, and the military with a robust communication network, as they were the primary 
// users of computers at that time.
