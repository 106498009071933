import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  Navigate
} from "react-router-dom";
import { Home, User, FileText, Briefcase, Menu, X } from 'lucide-react';
import { useState } from 'react';
import About from "./components/About"
import HomeBar from "./components/Home"
import Projects from "./components/Projects";
import Posts from "./components/Posts"
import "./App.css"
import Glossary from "./components/Glossary"
import SearchIcon from '@mui/icons-material/Search';
import HistoricalEvolutionInternet from "./components/pages/HistoricalEvolutionInternet"
import AuthPage from "./components/auth/AuthPage"
import Dashboard from "./components/urlshortener/Dashboard"
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

function NavLink({ to, children, icon: Icon, onClick }) {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  return (
      <Link className={`link ${isActive ? 'active' : ''}`} to={to} onClick={onClick}>
        {Icon && <Icon size={16} />}
        <span>{children}</span>
      </Link>
  );
}

// Wrapper component for the main blog layout
function BlogLayout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="blog-layout">
      <nav className="navbar">
        <button className="menu-button" onClick={toggleMenu}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
        <div className={`nav-content ${isMenuOpen ? 'show' : ''}`}>
          <NavLink to="" icon={Home} onClick={closeMenu}>Home</NavLink>
          <NavLink to="about" icon={User} onClick={closeMenu}>About</NavLink>
          <NavLink to="posts" icon={FileText} onClick={closeMenu}>Posts</NavLink>
          <NavLink to="glossary" icon={SearchIcon} onClick={closeMenu}>Glossary</NavLink>
          <NavLink to="projects" icon={Briefcase} onClick={closeMenu}>Projects</NavLink>
        </div>
      </nav>
      <main className="content">
        {children}
      </main>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* URL Shortener Routes */}
          <Route path="urlshortener">
            <Route index element={<AuthPage />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
          
          {/* Blog Routes */}
          <Route path="*" element={
            <BlogLayout>
              <Routes>
                <Route path="" element={<HomeBar />} />
                <Route path="posts" element={<Posts />} />
                <Route path="projects" element={<Projects />} />
                <Route path="about" element={<About />} />
                <Route path="glossary" element={<Glossary />} />
                <Route path="historicalinternet" element={<HistoricalEvolutionInternet />} />
              </Routes>
            </BlogLayout>
          } />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;